/* overrides */
.form_control {
  margin: 2px 0;
  padding: 0.5rem 1rem;
}
label {
  font-weight: 500;
  color: #444444;
}
.form_control:focus {
  margin: 0;
  border: 2px solid cornflowerblue;
}
.error_form:focus {
  border-color: red;
}
.valid_form,
.valid_form:focus {
  border-color: #50cd89;
}
.field_required::after {
  content: '*';
  margin-left: 2px;
  color: red;
}
.root {
  /* twnd-bg-[#74C043] twnd-min-h-screen twnd-relative twnd-flex twnd-items-center twnd-justify-center twnd-overflow-clip */
  background-color: #74c043;
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: clip;
}
.main_background_hck {
  position: absolute;
  opacity: 0.4;
  width: 13rem;
}
.main_background_hck_top {
  top: 0;
  right: -8px;
  transform: rotate(190deg);
}
.main_background_hck_bottom {
  left: 0;
  bottom: 0;
  transform: rotate(10deg);
}
.main {
  background-color: white;
  z-index: 10;
  width: 90%;
  border-radius: 0.25rem;
  margin: 2rem 0.5rem;
}
.main_inner_div {
  padding: 2rem;
}
.main_inner_left {
  position: relative;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-color: #74c043;
}
.main_inner_headline {
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
}
.main_inner_subtitle {
  color: white;
  font-size: 1rem;
  line-height: 1rem;
}
.main_inner_title {
  /* text-sm */
  font-size: 1.8rem;
  line-height: 1.25rem;
  color: white;
  font-weight: 700;
}
.main_inner_right {
  margin: 0 auto;
}
.login_logo_image {
  margin: 0 auto;
  width: 16rem;
  display: none;
}
.hck_logo_hero {
  width: 24rem;
  opacity: 0.4;
  position: absolute;
  bottom: -10px;
  right: -2px;
  display: none;
}
.macbook {
  /* twnd-w-40 md:twnd-w-72 twnd-absolute twnd-bottom-2/4 twnd-right-12 md:twnd--bottom-[60px] md:twnd--right-[70px] */
  width: 10rem;
  right: 10px;
  bottom: 75%;
  position: absolute;
}
.login_text {
  /* twnd-text-xl md:twnd-text-2xl twnd-font-bold twnd-py-6 */
  padding: 2rem 0;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.75rem;
}
.login_underlined_text {
  /* twnd-underline twnd-underline-offset-8 twnd-decoration-[#74C043] */
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration-color: #74c043;
}
.footer {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer_div {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer_p {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0;
  border-right: 2px solid #e5e7eb;
}
.footer_p_last {
  border-right: none;
}
/* for md: breakpoints */
@media only screen and (min-width: 768px) {
  .main_background_hck {
    width: 24rem;
  }
  .main {
    width: auto;
  }
  .main_inner_div {
    display: flex;
    column-gap: 2rem;
    align-items: stretch;
  }
  .main_inner_left {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 370px;
  }
  .main_inner_headline {
    flex-direction: column;
    text-align: left;
  }
  .main_inner_subtitle {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .main_inner_title {
    /* text-2xl */
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .main_inner_right {
    width: 320px;
  }
  .login_logo_image,
  .hck_logo_hero {
    display: block;
  }
  .macbook {
    width: 18rem;
    right: -20px;
    bottom: -20px;
  }
}

/* for lg: breakpoints */
@media only screen and (min-width: 1024px) {
  .main_inner_left {
    width: 512px;
  }
  .main_inner_title {
    /* text-3xl */
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

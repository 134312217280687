.aside-collapse {
  width: 70px !important;
}

@media (min-width: 992px) {
  /* .aside-collapse .aside-menu {
    width: 0px !important;
    transition: width 0.3s ease;
  } */
  .aside-collapsed-page .aside-menu {
    width: 70px !important;
  }
  .aside-collapsed-page .wrapper {
    transition: padding-left 0.3s ease;
    padding-left: 40px !important;
  }
  .aside-collapsed-page.wrapper.header {
    left: 70px !important;
    transition: left 0.3s ease;
  }
  .aside-collapsed-page .header {
    left: 70px !important;
    transition: left 0.3s ease;
  }
  .aside-collapsed-page .toolbar {
    left: 70px !important;
    transition: left 0.3s ease;
  }
  .aside-collapsed-page .footer .container-fluid {
    padding: 0 40px !important;
  }
}

@media (min-width: 992px) {
  .aside-collapsed-page .aside-logo {
    padding: 10px !important;
  }
}

@media only screen and (min-width: 1048px) {
  .responsive_divider {
    border-right: 3px solid #74c043;
  }
}

.expertise {
  margin-top: 6px;
}

.expertise p {
  margin-bottom: 2px;
}
.custom-pagination ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.custom-pagination ul li {
  font-size: 14px;
  cursor: pointer;
  border-radius: 6px;
  margin-right: 4px;
  padding: 0.375rem 0rem;
}

.custom-pagination ul li a {
  padding: 1rem;
}

.custom-pagination ul li:hover {
  background-color: hsl(96, 50%, 96%);
  color: #74c043;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-pagination ul li.selected {
  background-color: #74c043;
  color: white;
}

.custom-pagination ul li.selected:hover {
  background-color: #74c043;
  color: white;
}
